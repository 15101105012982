"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _ajax = require("rxjs/observable/dom/ajax");
var _assign = _interopRequireDefault(require("lodash/assign"));
var _merge = _interopRequireDefault(require("lodash/merge"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
// Libraries

var api = function api(store) {
  var state = store.getState();
  var API_URL = state.map.options.keolisApiUrl;
  var BLOB_CONTAINER_URL = 'https://dkcsavlusesa.blob.core.windows.net/';
  var baseSettings = {
    headers: {
      Authorization: state.map.auth.token,
      ApplicationId: 'standalone_map',
      'Content-Type': 'application/json'
    },
    responseType: 'json'
  };
  var layerDataBaseSettings = {
    headers: {
      Authorization: "Bearer ".concat(state.map.auth.mapLayerToken),
      'x-ms-version': '2017-11-09',
      'Access-Control-Request-Method': 'GET'
    },
    responseType: 'json'
  };
  return {
    request: function request(url) {
      var settings = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return (0, _ajax.ajax)((0, _merge["default"])({}, baseSettings, settings, {
        url: API_URL + url
      }));
    },
    get: function get(url) {
      return (0, _ajax.ajax)((0, _assign["default"])({}, baseSettings, {
        url: API_URL + url
      }));
    },
    post: function post(url, payload) {
      return (0, _ajax.ajax)((0, _assign["default"])({}, baseSettings, {
        url: API_URL + url,
        body: payload,
        method: 'POST'
      }));
    },
    put: function put(url, payload) {
      return (0, _ajax.ajax)((0, _assign["default"])({}, baseSettings, {
        url: API_URL + url,
        body: payload,
        method: 'PUT'
      }));
    },
    "delete": function _delete(url, payload) {
      return (0, _ajax.ajax)((0, _assign["default"])({}, baseSettings, {
        url: API_URL + url,
        body: payload,
        method: 'DELETE'
      }));
    },
    rawGet: function rawGet(url) {
      return (0, _ajax.ajax)((0, _assign["default"])({}, {
        url: url,
        responseType: 'json'
      }));
    },
    getLayerData: function getLayerData(url) {
      return (0, _ajax.ajax)((0, _assign["default"])({}, layerDataBaseSettings, {
        url: BLOB_CONTAINER_URL + url
      }));
    }
  };
};
var _default = api;
exports["default"] = _default;